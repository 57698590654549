import React, { Component, Fragment } from "react";
import { Button, Card } from "reactstrap";
import UserDetails from "./components/UserDetails.js";
import UserActions from "./components/UserActions.jsx";
import { EditProfileModal } from "./components/modals/";
import UserAssignedClients from "./components/UserAssignedClients/UserAssignedClients.js";

class ProfilePage extends Component {
  componentWillMount() {
    this.props.fetchClientsByLoggedInUser();
  }

  render() {
    const { activateModal } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-12 col-md-4 p-1">
            <Card className="p-2">
              <UserDetails />
              <Button
                className="btn btn-block"
                onClick={() => activateModal("editProfileModal")}
              >
                Edit Details
              </Button>
            </Card>
          </div>
          <div className="col-sm-12 col-md-8 p-1">
            <Card className="p-2">
              <UserAssignedClients />
            </Card>
            <Card className="p-2 mt-1">
              <UserActions />
            </Card>
          </div>
        </div>
        <EditProfileModal />
      </Fragment>
    );
  }
}

export default ProfilePage;
