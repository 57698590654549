import React, { useState } from "react";
import { Table } from "reactstrap";

const UserActions = (props) => {
  const [userActionsArray, setUserActionsArray] = useState([]);

  const loadUserActions = () => {
    if (Array.isArray(userActionsArray) && userActionsArray.length == 0) {
      fetch("/api/user/fetch-user-actions")
        .then(res => res.json())
        .then(res => setUserActionsArray(res));
    }
  };

  loadUserActions();

  return (
    <div className="text-center">
      <span>Actions Logged</span>
      <Table className="table=sm mt-2">
        <tbody>
          {userActionsArray.map((userAction) => (
            <tr>
              <td className="text-left">{userAction.actionName}</td>
              <td className="text-left">API used: {userAction.apiUsed}</td>
              <td className="text-left">{new Date(userAction.createdOn).toUTCString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UserActions;
