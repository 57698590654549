import { connect } from "react-redux";
import { default as component } from "./ContentOrderForm.jsx";

// Local Action
import {
  setContentOrderMinimumWords,
  setContentOrderMaximumWords,
  setContentOrderTurnAround,
  setContentOrderDueDate,
  setContentOrderShortCode,
  setContentOrderContentType,
  setContentOrderReceiveContentType,
  setContentOrderTemplateType,
  setContentOrderTitle,
  setContentOrderInstructions,
  setContentOrderDescription,
  setContentOrderSpecialNotes,
  setFormValidity,
} from "../../duck/actions";

// Local Selectors
import {
  getProjectParentCompanyName,
  getProjectParentCompanyClientNotes
} from "../../../../../duck/selectors.js";

import {
  getContentOrderMinimumWords,
  getContentOrderMaximumWords,
  getContentOrderTurnAround,
  getContentOrderDueDate,
  getContentOrderShortCode,
  getContentOrderContentType,
  getContentOrderReceiveContentType,
  getContentOrderTemplateType,
  getContentOrderTitle,
  getContentOrderInstructions,
  getContentOrderDescription,
  getContentOrderSpecialNotes,
} from "../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    companyName: getProjectParentCompanyName(state),
    companyClientNotes: getProjectParentCompanyClientNotes(state),
    minimumWords: getContentOrderMinimumWords(state),
    maximumWords: getContentOrderMaximumWords(state),
    turnAround: getContentOrderTurnAround(state),
    dueDate: getContentOrderDueDate(state),
    shortCode: getContentOrderShortCode(state),
    contentType: getContentOrderContentType(state),
    receiveContentType: getContentOrderReceiveContentType(state),
    templateType: getContentOrderTemplateType(state),
    orderTitle: getContentOrderTitle(state),
    orderInstructions: getContentOrderInstructions(state),
    orderDescription: getContentOrderDescription(state),
    specialNotes: getContentOrderSpecialNotes(state),
  };
};

const mapDispatchToProps = {
  setContentOrderMinimumWords,
  setContentOrderMaximumWords,
  setContentOrderTurnAround,
  setContentOrderDueDate,
  setContentOrderShortCode,
  setContentOrderContentType,
  setContentOrderReceiveContentType,
  setContentOrderTemplateType,
  setContentOrderTitle,
  setContentOrderInstructions,
  setContentOrderDescription,
  setContentOrderSpecialNotes,
  setFormValidity,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
